import React from 'react'
import Grid from '@mui/material//Grid'
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'


const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Grid container justifyContent='center'>
        <Grid 
          container 
          direction='column' 
          alignItems='center'
          sx={{maxWidth: '35rem'}} 
          spacing={6}
        >
          <Grid item>
            <StaticImage src='../images/nathanpic.jpeg' width={300} alt='nathan' />
          </Grid>
          <Grid item>
            <Typography align='center' sx={{ fontSize: '2rem' }}>Hi, I'm Nathan!</Typography>
            <Typography align='center' paragraph={true} sx={{ fontSize: '1.25rem', marginTop: '0.5rem' }}>
              I'm an engineer and entrepreneur based in Brooklyn, NY. 
              More about me <Link to='/about' alt='about'>here</Link>.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage